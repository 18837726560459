<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <DlgImageMedia ref="dlgImageMedia" :visible="dlgVisibleImageMedia" :route_type="cpFiles.route_type" :section="dlgImageMediaSection" @close="dlgImageMediaClose"></DlgImageMedia>
        <upload-files :visible="cpFiles.showDialog" :file_type="cpFiles.file_type" :route_type="cpFiles.route_type" :caller="caller" @upload-done="uploadDone" @close="cpFiles.showDialog=false"></upload-files>

        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ this.form.page_name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-toolbar dark color="panel1"><v-toolbar-title>Info</v-toolbar-title>
                    <v-spacer></v-spacer>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
            </v-toolbar> 
            <v-card>
                    <div class="d-flex flex-no-wrap">
                        <v-avatar class="ma-3" size="125" tile>
                            <v-img v-if="form.image_file_name" :src="this.form.file_url + this.form.image_file_name"></v-img>
                            <v-img v-else src="../../assets/image/no-image.png"></v-img>
                        </v-avatar>
                        
                        <div>
                            <v-card-title class="headline">Image</v-card-title>
                            <v-card-subtitle>{{ form.image_file_name}}</v-card-subtitle>
                            <v-card-actions>
                                <v-btn class="ml-2 mt-5 btnUpload" dark @click="showUpload('form.image_file_name')"><v-icon>mdi-plus</v-icon>Upload</v-btn>
                                <v-btn class="ml-2 mt-5 btnLibrary" dark @click="showDlgImageMedia('')"><v-icon>mdi-folder-image</v-icon>Library</v-btn>

                            </v-card-actions>
                        </div>
                    </div>
                </v-card>            
                <v-card class="mt-5">
                <v-form ref="mainForm">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="form.page_id" disabled label="Page Rec Id" outlined></v-text-field>                                                                
                                <v-select
                                    v-model = "form.page_category_code"
                                    :items="pageCategoryList"
                                    item-text="description"
                                    item-value="code"
                                    label="Category"
                                    standard
                                    :rules="inputRules"
                                    outlined
                                ></v-select>
                                <v-select
                                    v-model = "form.page_type_code"
                                    :items="pageTypeList"
                                    item-text="description"
                                    item-value="code"
                                    label="Type"
                                    standard
                                    :rules="inputRules"
                                    outlined
                                ></v-select>

                                <v-text-field v-model="form.page_code" label="Code" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.page_name" label="Name" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.title" label="Title" :rules="inputRules" outlined></v-text-field>
                                <editor :content.sync="form.content"></editor>
                                <v-text-field v-model="form.url_icon" label="Icon Url" outlined></v-text-field>
                                <v-text-field v-model="form.url_content" label="Url" outlined></v-text-field>
                                <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            :value="form.start_date"
                                            label="Start Date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.start_date" @change="dpicker.menu1 = false"></v-date-picker>
                                </v-menu>
                                <v-menu v-model="dpicker.menu2" :close-on-content-click="false" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            :value="form.end_date"
                                            label="Start Date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.end_date" @change="dpicker.menu2 = false"></v-date-picker>
                                </v-menu>

                                <v-select
                                    v-model = "form.status"
                                    :items="status_list"
                                    item-text="description"
                                    item-value="code"
                                    label="Status"
                                    standard
                                    :rules="inputRules"
                                    outlined
                                ></v-select>
                                
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>

                </v-card>

            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-toolbar dark color="panelSysInfo"><v-toolbar-title>System Info</v-toolbar-title>
                </v-toolbar>             
                <v-card class="mt-5">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-for="info in systemInfo" :key="info.alias" :value="form[info.name]" disabled :label="info.alias" outlined></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>

                </v-card>

                
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import DlgImageMedia from '@/components/DlgImageMedia'
import UploadFiles from "@/components/UploadFiles";
import ApPageService from "@/services/ApPageService"
import ApParameterService from "@/services/ApParameterService"

import Editor from "@/components/Editor.vue";

export default {
  components:{DlgMessage,Editor,UploadFiles,DlgImageMedia},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Marketing',disabled: false,href:'/merchants/',},
              {text:'Page',disabled: false,href:'/merchants/',},
              {text:'Details',disabled: false,href:'#',},
              ],
            status_list:[],
            pageCategorylist:[],
            pageTypelist:[],
            routeParam:[],
            form:{login_id:null,page_id:0},
            systemInfo:[
                {"name":"created_date","alias":"Created Date"},
                {"name":"created_by","alias":"Created By"},
                {"name":"created_at","alias":"Created At"},
                {"name":"updated_date","alias":"Updated Date"},
                {"name":"updated_by","alias":"Updated By"},
                {"name":"updated_at","alias":"Updated At"},
            ],

            logo:'',
            encPageName:'',

            inputRules: [
             v => !!v || 'This field is required'
            ],

            cpFiles:{
                file_type:'image',
                route_type:'pages',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},
            dlgVisibleImageMedia:false,           
            dlgImageMediaSection:"",

    }
  },
  async created() {
    this.routeParam['page_id'] = this.$route.params.page_id;
    this.routeParam['page_name'] = atob(this.$route.params.enc_page_name);
    this.getParameter();
    this.viewDetail();

  },
  
  methods:{

    async viewDetail(){
        this.form.page_id = this.routeParam.page_id
        try{
            ApPageService.view_page(this.form).then((res) => {
                this.form = res.data.data;
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }                
                console.log(res.data);
                this.status_list = res.data.statusList; 
               
            }).catch((e)=>{
                console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','View Page',e.response.data.error_message);
            })
            ;
        } catch(e) {
            console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    async save(){
        try{
            ApPageService.update_page(this.form).then(() => {
                this.$refs.dlgMsg.showDlgBox(true,'success','Update Page','Success.');
            }).catch((e)=>{
                console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Update Page',e.response.data.error_message);
            })
            ;
        } catch(e) {
            console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    
    dlgClose(){
        this.visible = false;        
    },
    uploadDone(e){
        //console.log(e);
        this.form.file_url = e.fileUrl;
        console.log("this." + e.caller + "='" + e.fileName + "'");
        eval("this." + e.caller + "='" + e.fileName + "'");
        //this.form.logo_file_name = e.fileName;
        //console.log(e.caller);
    },

    showUpload(uploadType){
        console.log('showupload');
        this.cpFiles.file_type = 'image';
        this.cpFiles.route_type = 'pages';
        this.caller = uploadType;
        //console.log(uploadType)
        this.cpFiles.showDialog = true;
    },
    async getParameter(){
        try{
            let parameter = {login_id:"",param_type:"add-page"}
            ApParameterService.get_parameter(parameter).then((res) => {
                this.pageCategoryList = res.data.pageCategoryList;
                this.pageTypeList = res.data.pageTypeList;
                console.log(res.data);
                
            }).catch((e)=>{
                console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Get Parameter',e.response.data.error_message);
            })
            ;
        } catch(e) {
            console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },

    dlgImageMediaClose(selectedImage){
        this.dlgVisibleImageMedia = false;

        if(selectedImage.imageFileName && selectedImage.section == ""){
            this.form.image_file_name = selectedImage.imageFileName
            this.form.file_url = selectedImage.fileUrl
            return
        }

        // this.form[selectedImage.section] = selectedImage.imageFileName;
        // this.form.file_url = selectedImage.fileUrl;
        // if(selectedImage.imageFileName && selectedImage.section =="items"){
        //     let e = {fileName:selectedImage.imageFileName,urlPreview:selectedImage.fileUrl}
        //     let totalImage = this.ciImages.length;
        //     let ciImageNew = {sequence:totalImage+1*10,file_url:e.urlPreview,image_file_name:e.fileName,preview:"",status:"ACTIVE"}
        //     this.ciImages.push(ciImageNew)
        //     this.saveIndicator = "(*)"
        //     console.log(ciImageNew);

        // }
    },
    showDlgImageMedia(section){
        this.dlgImageMediaSection = section;
        this.dlgVisibleImageMedia = true;
        
        
    },    

  }
}
</script>